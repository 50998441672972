<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Recent Transactions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="process_reference_no"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>

import DlgMessage from '@/components/DlgMessage'
import MpTransactionService from "@/services/MpTransactionService"
export default {
    components:{DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Transactions',disabled: false,href:'/customers',},
              {text:'Recent List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Merchant Name",value: "merchant_name"  },
                { text:"Outlet Name", value:"outlet_name" },
                { text:"Member Id", value:"login_id" },
                { text:"TX Date", value:"transaction_date" },
                { text:"Code", value:"eg_code" },                
                { text:"Reference No.", value:"reference_no" },
                { text:"Amount", value:"process_amount" },
                { text:"Points", value:"points" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    MpTransactionService.list_recent(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Merchant',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Merchant','Unknown Error');
                  
                }           

            },
            openDetail(value){
                let routeData = this.$router.resolve({ 
                    name: 'point_transaction_details', 
                    params: { 
                        process_reference_no: value.process_reference_no,
                    } 
                })
                window.open(routeData.href,'_blank')
                // this.$router.push({ name: 'point_transaction_details', params: { process_reference_no: value.process_reference_no } })
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },


    }

}
</script>